import React, { memo } from 'react'
import { formatter, toFarsiNumber, zeroFormat } from '../../../../helpers/tools'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import SandwichMenu from '../../../../components/header/SandwichMenu'
import OtherCoins from '../otherCoins/OtherCoins'
import * as DialogShow from '../../../../services/redux/dialog'
import { useDispatch } from 'react-redux'
import { config } from '../../../../config/config'
import { menu } from '../../../../config/menu'
import useTicker from '../../../../hooks/useTicker'
import _ from 'lodash'
import useCoinPrice from '../../../../hooks/useCoinPrice'

const HeadDetail = ({ setSelectCoin, selectCoin, navigate }
  : { setSelectCoin: any, selectCoin: any, navigate: any }) => {
  const dispatch = useDispatch<any>();
  const ticker = useTicker();
  const { coinsPairList } = useCoinPrice();
  // const userIsLogin = useSelector((state: any) => state.dataSaver.userIsLogin);
  // const [activeLogin, setActiveLogin] = useLocalStorage('activeLogin', "");

  const handelQuery = (item: any) => {
    setSelectCoin(item);
    navigate(menu.order.childs.orederPro.url + "/" + item?.symbol?.toUpperCase() + "-" + item?.myPair?.toUpperCase())
  }

  return (
    <div className='head headTop'>
      <SandwichMenu />
      <div className=' pairSelect ' onClick={() => dispatch(DialogShow.show(<OtherCoins isMarket={true} pairFlag={true} ListCoin={coinsPairList() ?? {}}
        close={() => dispatch(DialogShow.hide())}
        selectCoin={handelQuery} selectOrder={() => { }} />))} >
        <div className='imgPair cPointer'>
          <LazyLoadImage crossOrigin='anonymous' className='coin' src={`${config.imgUrl}${selectCoin?.symbol?.toUpperCase()}.png`} alt="" />
          <LazyLoadImage crossOrigin='anonymous' className='pair' src={selectCoin?.myPair == "tmn" ? "/icons/iranflag.svg" : `${config.imgUrl}${selectCoin?.myPair?.toUpperCase()}.png`} alt="" />
        </div>
        <div className='namePair cPointer'>
          <h3>{selectCoin?.pairSymbolShow}</h3>
          <p>{selectCoin?.nameFa ?? "---"}/ {selectCoin?.pairFa ?? "تومان"}</p>
        </div>
        <div className='arrowSelecting cPointer'>
          <LazyLoadImage src="/icons/m-IconArrowCircle.svg" alt="" />
        </div>

      </div>
      <div className='headerPriceShow'>
        <div className='showPrice'>
          <h4>{!_.isEmpty(selectCoin) && toFarsiNumber(formatter(ticker?.price(selectCoin?.pairSymbol), selectCoin?.pair[selectCoin?.myPair]?.precision ?? 2))}</h4>
          <div>
            <p>{!_.isEmpty(selectCoin) && toFarsiNumber(selectCoin?.symbol == "usdt" ? 1 : formatter(ticker.price(selectCoin?.symbol + 'usdt'), selectCoin?.pair["usdt"]?.precision ?? 2))}</p>
            <LazyLoadImage src="/icons/m-IconDollarCircle.svg" alt="" />
          </div>
        </div>
        <div className='decPrice'>
          <div>
            <p>تغییرات روزانه</p>
            {/* <LazyLoadImage src="/icons/m-IconArrowUpGray.svg" alt="" /> */}
          </div>
          <h5><b>{!_.isEmpty(selectCoin) && selectCoin?.myPair?.toUpperCase()}</b>&nbsp;{!_.isEmpty(selectCoin) && toFarsiNumber(formatter(ticker.open(selectCoin?.pairSymbol), selectCoin?.pair[selectCoin?.myPair]?.precision ?? 2))}</h5>
        </div>
        <div className='decPrice'>
          <div>
            <p>بیشترین قیمت 24h</p>
            <LazyLoadImage src="/icons/m-IconArrowUpGray.svg" alt="" />
          </div>
          <h5><b>{!_.isEmpty(selectCoin) && selectCoin?.myPair?.toUpperCase()}</b>&nbsp;{!_.isEmpty(selectCoin) && toFarsiNumber(formatter(ticker.high(selectCoin?.pairSymbol), selectCoin?.pair[selectCoin?.myPair]?.precision ?? 2))}</h5>
        </div>
        <div className='decPrice'>
          <div>
            <p>کمترین قیمت 24h</p>
            <LazyLoadImage src="/icons/m-IconArrowDownGrray.svg" alt="" />
          </div>
          <h5><b>{!_.isEmpty(selectCoin) && selectCoin?.myPair?.toUpperCase()}</b>&nbsp;{!_.isEmpty(selectCoin) && toFarsiNumber(formatter(ticker.low(selectCoin?.pairSymbol), selectCoin?.pair[selectCoin?.myPair]?.precision ?? 2))}</h5>
        </div>
        <div className='decPrice'>
          <div>
            <p>تغییر قیمت 24h</p>
            <LazyLoadImage src="/icons/m-IconClockGray.svg" alt="" />
          </div>
          <h5 className={ticker.change(selectCoin?.pairSymbol) > 0 ? "colorGreen" : "colorRed"}>
            {!_.isEmpty(selectCoin) && toFarsiNumber(ticker.change(selectCoin?.pairSymbol))} %
          </h5>
        </div>
        <div className='decPrice'>
          <div>
            <p>حجم معاملات روزانه</p>
            <LazyLoadImage src="/icons/m-IconClockGray.svg" alt="" />
          </div>
          <h5>
            {!_.isEmpty(selectCoin) && toFarsiNumber(zeroFormat(ticker?.volume(selectCoin?.pairSymbol)))}
          </h5>
        </div>
      </div>
    </div>
  )
}

export default memo(HeadDetail)