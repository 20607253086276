import { Box, CircularProgress, Divider, Slider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { config } from '../../../../config/config';
import { useDispatch, useSelector } from 'react-redux';
import { zeroFormat, removeComma, toEnglishDigits } from '../../../../helpers/tools';
import { marksSlidBar } from '../../../../config/variable';
import { useTranslation } from 'react-i18next';
import * as BackdropShow from '../../../../services/redux/backdrop';
import gather from '../../../../helpers/gather';
import _ from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useTicker from '../../../../hooks/useTicker';
import { menu } from '../../../../config/menu';
import { NavLink } from 'react-router-dom';
import { IoIosAddCircleOutline } from 'react-icons/io';
import * as DialogShow from '../../../../services/redux/dialog';
import NeedLogin from '../../../../components/NeedLogin';
import useSnack from '../../../../hooks/useSnack';
import ConfirmBuySellFast from '../../dialog/ConfirmBuySellFast';
import { useLocalStorage } from 'usehooks-ts';

const FastOrder = ({ orderChange, setOrderChange, coin, setReload }: any) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const mainWalletObj: any = useSelector((state: any) => state.user?.mainWalletObj);
  const listCoinObj: any = useSelector((state: any) => state.publicApis?.listCoinObj);
  const userIsLogin = useSelector((state: any) => state.dataSaver.userIsLogin);
  const [payment, setPayment] = useState(0);
  const [amount, setAmount] = useState(0);
  const [valueSlider, setValueSlider] = useState(100);
  const ticker = useTicker();
  const snackUse = useSnack();
  const [confiemOrder] = useLocalStorage('confiemOrder', "false");
  const [myLoading, setMyLoading] = useState(false);

  const handelChange = (e: any) => {
    if (amount > mainWalletObj[orderChange ? 'tmn' : coin?.symbol]?.free) {
      setAmount(removeComma(e));
      return
    }

    setAmount(removeComma(e));
    const price = ticker[orderChange ? 'buy' : 'sell'](coin.symbol + 'tmn');
    const total = orderChange
      ? Number(removeComma(e)) / Number(price ?? 0)
      : Number(removeComma(e)) * Number(price ?? 0)

    setPayment(removeComma(zeroFormat(total, listCoinObj[coin?.symbol]?.precision, false)));

    const balance = mainWalletObj[orderChange ? 'tmn' : coin?.symbol]?.free;
    const pers = 100 - ((Number(removeComma(e)) / balance) * 100);
    setValueSlider(pers <= 0 ? 0 : pers);
  }

  const handelChangeTMN = (e: any) => {
    // if(orderChange){
    //   zeroFormat(mainWalletObj[orderChange ? 'tmn' : coin?.symbol]?.free, listCoinObj[coin?.symbol]?.precision)
    // }

    setPayment(removeComma(e));
    const price = ticker[orderChange ? 'buy' : 'sell'](coin.symbol + 'tmn');
    const total = !orderChange
      ? Number(removeComma(e)) / Number(price ?? 0)
      : Number(removeComma(e)) * Number(price ?? 0)

    setAmount(removeComma(zeroFormat(total, listCoinObj[coin?.symbol]?.precision, false)));

    const balance = mainWalletObj[orderChange ? 'tmn' : coin?.symbol]?.free;
    const pers = 100 - ((Number(removeComma(total)) / balance) * 100);
    setValueSlider(pers <= 0 ? 0 : pers);
  }

  const handleSliderBar = (event: Event, newValue: number | number[]) => {
    setValueSlider(newValue as number);
    const balance = mainWalletObj[orderChange ? 'tmn' : coin?.symbol]?.free;

    const percent = 100 - Number(newValue);

    const amount = Number(balance) * Number(percent / 100) || 0;
    handelChange(zeroFormat(amount, listCoinObj[coin?.symbol]?.precision, false))
  }

  const confirmSend = () => {
    if (_.isEmpty(coin)) {
      snackUse.showError('لطفا یک ارز را انتخاب کنید');
      return
    }

    if (payment <= 0) {
      snackUse.showError('مقدار را وارد کنید');
      return
    }


    if (confiemOrder == "true") {
      send()
    } else {
      const data = {
        "coin": coin,
        "side": orderChange ? "buy" : "sell",
        "amount": orderChange ? Number(toEnglishDigits(payment?.toString())) : Number(toEnglishDigits(amount?.toString())),
      }
      dispatch(DialogShow.show(<ConfirmBuySellFast data={data} send={send} close={() => dispatch(DialogShow.hide())} />))
    }
  }

  const send = async () => {
    setMyLoading(true);

    const body = {
      'coin': coin._id,
      'pair': 'tmn',
      'side': orderChange ? 'buy' : 'sell',
      'price': ticker[orderChange ? 'buy' : 'sell'](coin?.symbol + 'tmn'),
      'amount': orderChange ? Number(toEnglishDigits(payment?.toString())) : Number(toEnglishDigits(amount?.toString())),
      "trade": "otc",
      "stop":null
    }

    const result = await gather(`${config.api}/v1/order`).post(body);

    if (result.message === 200) {
      dispatch(DialogShow.hide());
      setReload(new Date().getTime().toString());
      snackUse.showSaccess('سفارش شما با موفقیت ثبت شد');
      setValueSlider(100);
      setAmount(0);
      setPayment(0);
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }
    dispatch(BackdropShow.hide());
    setMyLoading(false);
  }

  useEffect(() => {
    setAmount(0);
    setPayment(0);
    setValueSlider(100);
  }, [coin])


  const handleTitleBar = () => {
    let title = `${zeroFormat(ticker[orderChange ? 'buy' : 'sell'](coin?.symbol + 'tmn'))}`
      + " " + "تومان"
      + " | " + (orderChange ? "خرید" : "فروش")
      + " " + (coin?.nameFa ?? " -- ")
      + " " + "فوری"
      + " " + menu.order.childs.fastOrder.title;

    document.title = title
  }

  useEffect(() => {
    handleTitleBar()
  }, [orderChange, coin, ticker]);

  return (
    <>
      <div className='stepOrder'>
        <div>
          <span className='icon-SecurityCard'></span>
          <h4>مرحله دوم <b>نوع معامله</b></h4>
          <div className='dividerVer'></div>
        </div>
        <p>نوع معامله را مشخص کنید</p>
      </div>
      <div className='bodyFastOrder'>
        <div className='right'>
          <div className='btnChangeOrder'>
            <button className={`buy ${orderChange && 'active'}`} onClick={() => { setOrderChange(true); setPayment(0); setAmount(0); setValueSlider(100); }}>
              <span className='m-IconBuy'></span>
              <p>خرید</p>
            </button>
            <button className={`sell ${!orderChange && 'active'}`} onClick={() => { setOrderChange(false); setPayment(0); setAmount(0); setValueSlider(100); }}>
              <span className='m-IconSell'></span>
              <p>فروش</p>
            </button>
          </div>
          <div className='enterAmount'>
            <div className='imgInput'>
              <LazyLoadImage crossOrigin='anonymous' src={!orderChange ? `${config.imgUrl}${coin?.symbol?.toUpperCase()}.png` : '/icons/iranflag.svg'} alt='' />
              <NumericFormat allowNegative={false} thousandSeparator={true} value={amount > 0 ? amount : ''} onChange={(e) => { handelChange(e.target.value) }} placeholder='پرداخت میکنم' />
            </div>
            <div className='btnExchange' onClick={() => { setOrderChange(!orderChange); setPayment(amount); setAmount(payment); setValueSlider(100); }}>
              <LazyLoadImage crossOrigin='anonymous' src='/icons/m-IconRepeat.svg' alt='' />
            </div>
            <div className='imgInput'>
              <LazyLoadImage crossOrigin='anonymous' src={orderChange ? `${config.imgUrl}${coin?.symbol?.toUpperCase()}.png` : '/icons/iranflag.svg'} alt='' />
              <NumericFormat allowNegative={false} thousandSeparator={true} value={payment > 0 ? payment : ''} onChange={(e) => handelChangeTMN(e.target.value)} placeholder='دریافت میکنم' />
            </div>
          </div>
          <p className='wrningText opacity0'>مقدار وارد شده، از حداکثر مقدار مجاز بیشتر می‌باشد.</p>
          <div className='priceNow'>
            <h4>قیمت تومانی در لحظه:  <b>TMN {zeroFormat(ticker[orderChange ? 'buy' : 'sell'](coin?.symbol + 'tmn'))}</b></h4>
            <h4>قیمت دلاری در لحظه:  <b>{zeroFormat(coin?.symbol == 'usdt' ? 1 : ticker[orderChange ? 'buy' : 'sell'](coin?.symbol + 'usdt'), listCoinObj[coin?.symbol]?.precision, false)}$</b></h4>
          </div>
          <div className='sliderRage my-20'>
            <Box sx={{ width: '100%' }}>
              <Slider
                aria-label='Restricted values'
                track='inverted'
                value={typeof valueSlider === 'number' ? valueSlider < 0 ? 100 : valueSlider : 100}
                onChange={handleSliderBar}
                marks={marksSlidBar}
              // step={25}
              // sx={{
              //   color: theme.palette.mode === 'dark' ? '#fff' : '#0f0f0f33',
              //   '& .MuiSlider-track': {
              //     border: `3px solid ${'#2424da'}`,
              //     boxShadow: '0px 7.45px 14.91px 1.45px #2424da',
              //   },
              //   '& .MuiSlider-thumb': {
              //     width: 19,
              //     height: 19,
              //     backgroundColor: '#2424da',
              //     border: `4px solid ${'#2424da'}`,
              //     // position: 'relative',
              //     '&::before': {
              //       boxShadow: '1px 12px 20px 3px #2424da',
              //     },
              //     '&:hover, &.Mui-focusVisible, &.Mui-active': {
              //       boxShadow: 'none',
              //     },
              //     '&::after': {
              //       position: 'absolute',
              //       content: '""',
              //       width: '2px',
              //       height: '15px',
              //       background: '#2424da',
              //       top: '23px',
              //       right: '5px',
              //       borderRadius: '0px'
              //     },
              //   },
              //   '& .MuiSlider-mark': {
              //     backgroundColor: '#e0e0e0',
              //     width: 8,
              //     height: 8,
              //     borderRadius: 50,
              //   },
              //   '& .MuiSlider-markActive': {
              //     backgroundColor: `${'#2424da'}`,
              //   }
              // }}
              />
            </Box>
          </div>

          {userIsLogin
            ?

            myLoading
              ? <div className={`loadingCircularOrder ${orderChange ? 'buy' : 'sell'}`}><CircularProgress size={28} style={{ color: "#fff" }} /></div>
              : <div className={`btnConfirm ${orderChange ? 'buy' : 'sell'}`} onClick={confirmSend}>
                <p> (با تایید {orderChange ? 'خرید' : 'فروش'} قوانین و مقررات را می پذیرم)</p>
                <Divider variant='middle' orientation='vertical' flexItem />
                <button>تایید {orderChange ? 'خرید' : 'فروش'}</button>
              </div>
            : <div className={`btnConfirm justifyCenter ${orderChange ? 'buy' : 'sell'}`}
              onClick={() => dispatch(DialogShow.show(<NeedLogin submit={() => { dispatch(DialogShow.hide()) }} />))}>
              <p className='textCenter'>ثبت نام - ورود</p>
            </div>
          }
        </div>
        {userIsLogin
          && <div className='left'>
            <NavLink
              to={orderChange ? menu.financial.childs.depositAll.childs.depositCash.childs.default.url : menu.financial.childs.depositAll.childs.depositCoin.url + "/" + coin?.symbol?.toUpperCase()}
              className='cPointer flexCenter ml-5'>
              <IoIosAddCircleOutline color='var(--color-textDone)' />
            </NavLink>
            <h4>موجودی شما : </h4>
            <h4 className='cPointer mr-3' onClick={() => handelChange(mainWalletObj[orderChange ? 'tmn' : coin?.symbol]?.free)}
            > {zeroFormat(mainWalletObj[orderChange ? 'tmn' : coin?.symbol]?.free)}</h4>
            <h4 className='mr-2'>{orderChange ? 'TMN' : coin?.symbol?.toUpperCase()}</h4>
          </div>
        }
      </div>
    </>
  )
}

export default FastOrder