import React, { memo, useState } from 'react'
import BuySell from './BuySell'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { NavLink } from 'react-router-dom'
import { Fade, Tooltip } from '@mui/material'
import TradingViewPro from '../../../../components/TradingViewPro'
import { useDispatch, useSelector } from 'react-redux'
import * as DialogShow from '../../../../services/redux/dialog'
import FullScreenMap from './FullScreenMap'
import { useLocalStorage } from 'usehooks-ts'
import { menu } from '../../../../config/menu'
import ResponsiveOrderBook from './ResponsiveOrderBook'
import Chart from '../../../../components/Chart'

const CenterBoxPro = ({ selectCoin, setReload, priceB, setPriceB, priceS, setPriceS, amountB, setAmountB, amountS, setAmountS, totalS, setTotalS, totalB, setTotalB }
  : { selectCoin: any, setReload: any, priceB: any, setPriceB: any, priceS: any, setPriceS: any, amountB: any, setAmountB: any, amountS: any, setAmountS: any, totalS: any, setTotalS: any, totalB: any, setTotalB: any }) => {
  const dispatch = useDispatch<any>();
  const [typeOrder, setTypeOrder] = useState(false);
  const [theme] = useLocalStorage('themeEX', "light");
  const [typePrice, setTypePrice] = useState("limit");
  const orderBook = useSelector((state: any) => state.orderBook?.orderBook);


  return (
    <div className='centerBox '>
      <div className='mapChart'>
        <div className='fullScreen' onClick={() => dispatch(DialogShow.show(<FullScreenMap close={() => dispatch(DialogShow.hide())} symbol={selectCoin?.pairSymbol.toUpperCase()} />))}>
          <span className='icon-IConFingerScan'></span>
        </div>
        <TradingViewPro symbolCoin={selectCoin?.pairSymbol.toUpperCase()} className='h100' theme={theme} />
        {/* <iframe className='iframeTradingViewWidget'
          src={`https://www.tradingview-widget.com/widgetembed/?hideideas=1&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&locale=en#%7B%22symbol%22%3A%22${selectCoin?.symbol?.toLocaleUpperCase() == "USDT" ? "USDTUSD" : selectCoin?.symbol?.toLocaleUpperCase() + "USDT"}%22%2C%22frameElementId%22%3A%22tradingview_db12a%22%2C%22interval%22%3A%2260%22%2C%22hide_top_toolbar%22%3A%221%22%2C%22hide_side_toolbar%22%3A%220%22%2C%22save_image%22%3A%220%22%2C%22studies%22%3A%22%5B%5D%22%2C%22theme%22%3A%22light%22%2C%22style%22%3A%221%22%2C%22timezone%22%3A%22Etc%2FUTC%22%2C%22studies_overrides%22%3A%22%7B%7D%22%2C%22utm_source%22%3A%22demo.exnovin.net%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22chart%22%2C%22utm_term%22%3A%22USDTUSD%22%2C%22page-uri%22%3A%22demo.exnovin.net%2Forder%2Fmarket%22%7D`}>
        </iframe> */}
        {/* <ChartTradingView symbol={selectCoin?.symbol?.toLocaleUpperCase()} /> */}
        {/* <Chart symbol={selectCoin.symbol.toUpperCase()} /> */}
      </div>
      <ResponsiveOrderBook orders={orderBook}
        setTotalS={setTotalS} setTotalB={setTotalB}
        setAmountS={setAmountS} setAmountB={setAmountB}
        setPriceB={setPriceB} setPriceS={setPriceS} selectCoin={selectCoin} pair={selectCoin?.myPair} />
      <div className='CreateOrderBox'>
        <div className='btnChangeType'>
          <button onClick={() => setTypeOrder(false)} className={`item ${!typeOrder && "active"}`}>اسپات</button>
          {/* <button onClick={() => setTypeOrder(true)} className={`item ${typeOrder && "active"}`}>تعهدی</button> */}
          <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} enterDelay={500} leaveDelay={200}
            title="... به زودی">
            <button className={`item opacityHalf`}>تعهدی</button>
          </Tooltip>
        </div>
        <div className='changePrice'>
          <div className='btns'>
            <button onClick={() => setTypePrice("limit")} className={`item ${typePrice == "limit" && "active"}`}>قیمت محدود</button>
            <button onClick={() => setTypePrice("market")} className={`item ${typePrice == "market" && "active"}`}>فوری</button>
            <button onClick={() => setTypePrice("stop")} className={`item ${typePrice == "stop" && "active"}`}>حد ضرر Stop Limit</button>
          </div>
          <div className='learningOrderEx'>
            <p className='p1'>آموزش معامله در اکس نوین</p>
            <p className='p2'>آموزش معاملات </p>
            <NavLink to={menu.learning.url}>کلیک کنید</NavLink>
            <LazyLoadImage src="/icons/m-IconArrowLeft.svg" alt="" />
          </div>
        </div>
        <div className='buyAndSellBox' >
          <BuySell ordersBook={orderBook?.sell?.length > 0 ? orderBook?.sell[0]?.price : []} total={totalB} setTotal={setTotalB} amount={amountB} setAmount={setAmountB} price={priceB} setPrice={setPriceB} setReload={setReload}
            flag={true} coin={selectCoin} base={selectCoin?.myPair} typePrice={typePrice} />
          <BuySell ordersBook={orderBook?.buy?.length > 0 ? orderBook?.buy[0]?.price : []} total={totalS} setTotal={setTotalS} amount={amountS} setAmount={setAmountS} price={priceS} setPrice={setPriceS} setReload={setReload}
            flag={false} coin={selectCoin} base={selectCoin?.myPair} typePrice={typePrice} />
        </div>
      </div>
    </div>
  )
}

export default memo(CenterBoxPro)