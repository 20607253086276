import Cookies from "js-cookie";

const getContent = async (url: string, method: string, headers: any, body: any): Promise<any> => {
  // add Authorization for auth
  if (!headers['Authorization']) {
    headers['Authorization'] = Cookies.get('accessTokenPanelEx');
  }

  // add contentType for json if it is empty
  if (!headers['content-type']) {
    headers['content-type'] = 'application/json';
  }

  if (headers['content-type'] === 'form-data') {
    delete headers['content-type'];
  }

  // convert form body to JSON if contentType is json
  if (headers['content-type'] === 'application/json' && body) {
    body = JSON.stringify(body);
  }

  return await fetch(url, {
    method: method,
    headers: headers,
    body: (body !== '{}') ? body : undefined,
  })
    .then(async (response) => {
      const res = await response.json();
      res.code = response.status;
      return res;
    })
    .catch(error => {
      // console.log('Fetch error', error);
      return {};
    });
}

const gather = (url: string) => {
  const get = async (headers: any = {}) => {
    return getContent(url, 'GET', headers, {});
  }

  const post = async (body: {} = {}, headers: any = {}) => {
    return getContent(url, 'POST', headers, body);
  }

  const del = async (body: {} = {}, headers: any = {}) => {
    return getContent(url, 'DELETE', headers, body);
  }

  const put = async (body: {} = {}, headers: any = {}) => {
    return getContent(url, 'PUT', headers, body);
  }
  const upload = async (body: {} = {}, headers: any = {}) => {
    if (!headers['content-type']) {
      headers['content-type'] = 'form-data';
    }

    return getContent(url, 'POST', headers, body);
  }

  return {
    delete: del,
    get,
    post,
    put,
    upload
  }
}

export default gather;
