import { Skeleton, Stack } from '@mui/material';
import DepthApi from './DepthApi';
import _ from 'lodash';

const ResponsiveOrderBook = ({ orders, selectCoin, pair, setPriceB, setPriceS, setAmountB, setAmountS, setTotalS, setTotalB }:
  { orders: any, selectCoin: any, pair: any, setPriceB: any, setPriceS: any, setAmountB: any, setAmountS: any, setTotalS: any, setTotalB: any }) => {

  return (
    <div className='responsOrderBook'>
      <div className='tbOpenOrderList'>
        <div className='head'>
          <div className='tr'>
            <div className='th'><p>قیمت ({selectCoin?.myPair.toUpperCase()})</p></div>
            <div className='th'><p>مقدار ({selectCoin?.symbol.toUpperCase()})</p></div>
            <div className='th'><p>قیمت کل ({selectCoin?.myPair.toUpperCase()})</p></div>
          </div>
        </div>
        <div className='body'>
          {(_.isEmpty(orders) || orders?.buy?.length == 0)
            ? <div className='loadingBook'>
              <Stack spacing={0} style={{ width: "100%" }}>
                {new Array(15)?.fill("a").map((i, n) =>
                  <Skeleton key={n} variant="text" sx={{ fontSize: '2rem' }} />
                )}
              </Stack>
            </div>
            :
            <DepthApi bgGreen={true}
              setTotalS={setTotalS} setTotalB={setTotalB}
              setAmountS={setAmountS} setAmountB={setAmountB}
              setPriceB={setPriceB} setPriceS={setPriceS}
              orderBookData={[...orders?.buy]?.reverse()} precision={selectCoin.pair[pair].precision} />}
        </div>
      </div>
      <div className='tbOpenOrderList'>
        <div className='head'>
          <div className='tr'>
            <div className='th'><p>قیمت ({selectCoin?.myPair.toUpperCase()})</p></div>
            <div className='th'><p>مقدار ({selectCoin?.symbol.toUpperCase()})</p></div>
            <div className='th'><p>قیمت کل ({selectCoin?.myPair.toUpperCase()})</p></div>
          </div>
        </div>
        <div className='body'>
          {(_.isEmpty(orders) || orders?.sell?.length == 0)
            ? <div className='loadingBook'>
              <Stack spacing={0} style={{ width: "100%" }}>
                {new Array(15)?.fill("a").map((i, n) =>
                  <Skeleton key={n} variant="text" sx={{ fontSize: '2rem' }} />
                )}
              </Stack>
            </div>
            :
            <DepthApi
              setTotalS={setTotalS} setTotalB={setTotalB}
              setAmountS={setAmountS} setAmountB={setAmountB}
              setPriceB={setPriceB} setPriceS={setPriceS}
              orderBookData={[...orders?.sell]?.reverse()} precision={selectCoin.pair[pair].precision} />}
        </div>
      </div>
    </div>
  )
}

export default ResponsiveOrderBook