import '././asset/css/app.scss';
import { Alert, Backdrop, Dialog, Drawer, Menu, Snackbar } from '@mui/material';
import { cookieRefreshToken, logOut } from './helpers/authUser';
import { useDispatch, useSelector } from 'react-redux';
import * as BackdropShow from './services/redux/backdrop';
import * as SnackbarShow from './services/redux/snackbar';
import * as PublicApis from './services/redux/publicApis';
import * as DialogShow from './services/redux/dialog';
import * as DrawerState from './services/redux/drawer';
import * as MenuState from './services/redux/menu';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { config } from './config/config';
import gather from './helpers/gather';
import Main from './pages/Main';
import Cookies from 'js-cookie';
import { connect } from './services/socket';
import { useLocalStorage } from 'usehooks-ts'
import { getTickers } from './services/redux/ticker';
import { getAccountBalance, getCards, getFavorite, getProfile, getWalletBalance } from './services/redux/user';
import * as DataSaver from './services/redux/dataSaver';
import useSnack from './hooks/useSnack';
import { useTranslation } from 'react-i18next';


const App = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);
  const dialogSelector = useSelector((state: any) => state.dialog);
  const snackbarSelector = useSelector((state: any) => state.snackbar);
  const backdropSelector = useSelector((state: any) => state.backdrop);
  const menuSelector = useSelector((state: any) => state.menu);
  const drawerSelector = useSelector((state: any) => state.drawer);
  const [theme] = useLocalStorage('themeEX', "light");
  const [warningRule, setWarningRule] = useLocalStorage('alertRule', "");
  const snackUse = useSnack();
  const { t } = useTranslation();

  const getPublicData = async () => {
    await Promise.allSettled([
      dispatch(PublicApis.listCoin()),
      dispatch(PublicApis.listNetwork()),
      dispatch(getTickers()),
      dispatch(PublicApis.listLevel()),
      dispatch(PublicApis.setting()),
      // dispatch(PublicApis.pageLearn()),
    ]);
  }

  const getUserData = async () => {
    await Promise.allSettled([
      // dispatch(PublicApis.listCoin()),
      // dispatch(PublicApis.listNetwork()),
      // dispatch(getTickers()),
      // dispatch(PublicApis.listLevel()),
      // dispatch(PublicApis.setting()),
      dispatch(getProfile()),
      dispatch(getWalletBalance()),
      dispatch(getAccountBalance()),
      dispatch(PublicApis.codeUp25()),
      dispatch(getCards()),
      dispatch(getFavorite()),
      dispatch(PublicApis.alarm()),
      dispatch(PublicApis.notice()),
    ]);
    dispatch(BackdropShow.hide());
  }

  const checkAuth = async () => {
    dispatch(BackdropShow.show());

    await getPublicData();

    if (Cookies.get('refreshTokenPanelEx')) {
      const result = await gather(`${config.api}/v1/auth/refreshToken`).get({ 'Authorization': cookieRefreshToken() });
      if (result) {
        if (result.code === 200) {
          Cookies.set('accessTokenPanelEx', result.data.accessToken, { expires: 30 * 24 * 60 * 60, path: '/' });
          await getUserData();
          dispatch(DataSaver.changeUserIsLogin(true));
        } else {
          if (result.message == 401 || result.code == 401) {
            logOut();
          }
          dispatch(DataSaver.changeUserIsLogin(false))
          navigate("/");
        }
      } else {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        await checkAuth();
      }
    } else {
      dispatch(DataSaver.changeUserIsLogin(false))
    }

    dispatch(BackdropShow.hide());
    dispatch(DataSaver.changeUserIsReady(true));
    setIsReady(true)
  }

  useEffect(() => {
    if (!warningRule) {
      setWarningRule("true");
    }
    connect(dispatch, t, snackUse);
    checkAuth();
  }, [])

  useEffect(() => {
    document.getElementById('MY-BODY')?.setAttribute('data-theme', theme);
  }, [theme])

  return (
    <div className='App' data-theme={theme}>
      <Drawer open={drawerSelector.open} PaperProps={{ style: drawerSelector.size }} anchor={drawerSelector.anchor} onClose={() => dispatch(DrawerState.hide())}>
        {drawerSelector.content}
      </Drawer>
      <Dialog className='DialogStyle'
        //  TransitionComponent={dialogSelector.transition} 
        PaperProps={{ style: { width: "unset ", borderRadius: "20px" } }} open={dialogSelector.open} onClose={() => dispatch(DialogShow.hide())} maxWidth={false} fullWidth={true} >
        {dialogSelector.content}
      </Dialog>
      <Dialog className='DialogStyle'
        PaperProps={{ style: { width: "unset ", borderRadius: "20px" } }} open={dialogSelector.open2}
        // onClose={() => dispatch(DialogShow.hide2())}
        maxWidth={false} fullWidth={true} >
        {dialogSelector.content2}
      </Dialog>
      <Snackbar
        open={snackbarSelector.open}
        autoHideDuration={3000}
        onClose={() => dispatch(SnackbarShow.hide())}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} >
        <Alert variant="filled" severity={snackbarSelector.severity} sx={{ width: '100%' }}> &nbsp; {snackbarSelector.text}</Alert>
      </Snackbar>
      <Backdrop open={backdropSelector.open} className='BackdropStyle' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1030 }}>
        <video className='imgMobile' muted autoPlay loop >
          <source src="/video/loadingEX.mp4" type="video/mp4" />
        </video>
        {/* <CircularProgress style={{ color: "#2424da" }} /> */}
      </Backdrop>
      <Menu
        id="basic-menu"
        anchorEl={menuSelector.anchorEl}
        open={menuSelector.open}
        onClose={() => dispatch(MenuState.hide())}
        anchorOrigin={{ vertical: 'bottom', horizontal: menuSelector.horizontal }}
        transformOrigin={{ vertical: 'top', horizontal: menuSelector.horizontal }}
        MenuListProps={{ 'aria-labelledby': 'lock-button', role: 'listbox', }}>
        {menuSelector.content}
      </Menu>
      {isReady && <Main />}
    </div>
  );
}

export default App;
