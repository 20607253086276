import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar';
import _ from 'lodash';
import HistoryPro from './items/pro/HistoryPro';
import HeadDetail from './items/pro/HeadDetail';
import OrderBook from './items/pro/OrderBook';
import LastOrder from './items/pro/LastOrder';
import CenterBoxPro from './items/pro/CenterBoxPro';
import Header from '../../components/header/Header';
import Footer from '../../components/Footer';
import { socket } from '../../services/socket';
import { Skeleton } from '@mui/material';
import { getOrderBook, updateOrderBook } from '../../services/redux/orderBook';

const OrderPro = () => {
  const dispatch = useDispatch<any>();
  const listAllPairObj = useSelector((state: any) => state.publicApis?.listAllPairObj);
  const userIsLogin = useSelector((state: any) => state.dataSaver.userIsLogin);
  const [selectCoin, setSelectCoin]: any = useState(listAllPairObj["usdttmn"] ?? {});
  const navigate = useNavigate();
  const { coinPairParam } = useParams();
  const [reload, setReload] = useState('')
  const [priceB, setPriceB] = useState(0);
  const [priceS, setPriceS] = useState(0);
  const [amountB, setAmountB] = useState(0);
  const [amountS, setAmountS] = useState(0);
  const [totalB, setTotalB] = useState(0);
  const [totalS, setTotalS] = useState(0);

  const handelOrdersBook = (result: any) => {
    if (selectCoin?.pairSymbol == (result?.symbol + result?.pair + "")) {
      dispatch(updateOrderBook(result));
    }
  }

  const getData = async () => {
    dispatch(getOrderBook(`${selectCoin.symbol}/${selectCoin?.myPair}`))
  };


  useEffect(() => {
    getData();
  }, [selectCoin]);

  useEffect(() => {
    setTimeout(async () => {
      const coinParams = coinPairParam?.split("-")[0]?.toLocaleLowerCase();
      const PairParams = coinPairParam?.split("-")[1]?.toLocaleLowerCase();
      if (coinParams && PairParams) {
        setSelectCoin(listAllPairObj[coinParams?.toLocaleLowerCase() + PairParams?.toLocaleLowerCase()])
      }
    }, 10);
  }, []);


  useEffect(() => {
    dispatch(updateOrderBook({}));
    socket.emit('orderBook', selectCoin?.pairSymbol);
    socket.on('orderBook', handelOrdersBook);

    return () => {
      socket.emit('unOrderBook', selectCoin?.pairSymbol);
      socket.removeListener('orderBook', handelOrdersBook);
    };
  }, [selectCoin]);

  return (
    <>
      <div className='bodyMain'>
        <div className='newContainer'>
          <Header inMarket={true} title={"بازار حرفه ای"} />
          <div className='OrderPro'>
            {_.isEmpty(listAllPairObj)
              ? <Skeleton variant="text" className='width_100d' sx={{ fontSize: '5rem' }} />
              : <HeadDetail selectCoin={selectCoin} navigate={navigate} setSelectCoin={setSelectCoin} />
            }
            <div className={`bodyBox ${_.isEmpty(listAllPairObj) ? "forceShowNav" : ""}`}>
              <Navbar flag={true} />
              {_.isEmpty(listAllPairObj)
                ? <div className='skeletonMarket'>
                  <div className='skItem1'>
                    <Skeleton variant="rectangular" className='width_100d' />
                  </div>
                  <div className='skItem2'>
                    <Skeleton variant="rectangular" className='width_100d' />
                  </div>
                </div>
                : <>
                  <LastOrder selectCoin={selectCoin} navigate={navigate} setSelectCoin={setSelectCoin} />
                  <CenterBoxPro
                    totalS={totalS} setTotalS={setTotalS} totalB={totalB} setTotalB={setTotalB}
                    amountB={amountB} setAmountB={setAmountB} amountS={amountS} setAmountS={setAmountS}
                    priceB={priceB} setPriceB={setPriceB} priceS={priceS} setPriceS={setPriceS} selectCoin={selectCoin} setReload={setReload} />
                  <OrderBook
                    setTotalS={setTotalS} setTotalB={setTotalB}
                    setAmountS={setAmountS} setAmountB={setAmountB}
                    setPriceB={setPriceB} setPriceS={setPriceS} selectCoin={selectCoin} pair={selectCoin?.myPair} />

                </>}
            </div>
            {userIsLogin && <HistoryPro reload={reload} />}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default OrderPro
