import React, { useState } from 'react'
import { CircularProgress, DialogContent, Divider } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { config } from '../../../config/config';
import { toEnglishDigits, zeroFormat } from '../../../helpers/tools';
import moment from 'jalali-moment';
import gather from '../../../helpers/gather';
import { useTranslation } from 'react-i18next';
import useSnack from '../../../hooks/useSnack';
import { useDispatch } from 'react-redux';
import VerifyWithdrawCoin from './VerifyWithdrawCoin';
import * as DialogShow from '../../../services/redux/dialog';
import * as BackdropShow from '../../../services/redux/backdrop';

const ConfirmWithdraw = ({ data, close, profile, resetAll }: { data: any, close: any, profile: any, resetAll: any }) => {
  const [myLoading, setMyLoading] = useState(false);
  const { t } = useTranslation();
  const snackUse = useSnack();
  const dispatch = useDispatch<any>();

  const sendCode = async () => {
    setMyLoading(true);

    const body: any = {
      coin: data?.coin?._id,
      network: data?.network?.network?._id,
      receiver: toEnglishDigits(data?.receiver),
      amount: Number(toEnglishDigits(data?.amount?.toString())),
    }

    if (data?.memo?.trim()?.length > 0) {
      body["memo"] = data?.memo
    }

    const result = await gather(`${config.api}/v1/withdraw`).post(body);
    if (result.message === 200) {
      dispatch(DialogShow.show(<VerifyWithdrawCoin body={body} mobile={profile?.mobile ?? "---"} submit={submit} type={result?.data?.type} close={() => dispatch(DialogShow.hide())} />))
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false);
  }

  const submit = async (body: any) => {
    dispatch(BackdropShow.show());

    const result = await gather(`${config.api}/v1/withdraw/confirm`).post(body);
    if (result.message === 200) {
      snackUse.showSaccess("عملیات با موفقیت انجام شد");
      resetAll();
      dispatch(DialogShow.hide())
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    dispatch(BackdropShow.hide());
  }


  return (
    <>
      <DialogContent className='detailWithdrawLogoBox newStyle'>
        {/* <LazyLoadImage crossOrigin='anonymous' src={`/images/logo/svg/Exnovin-${theme == "dark" ? "logo-4" : "logo-2"}.svg`} alt="" /> */}
        <LazyLoadImage crossOrigin='anonymous' src={`/images/logo/svg/Exnovin-logo.svg`} alt="" />
        <span className='iconClose' onClick={close}></span>
      </DialogContent>
      <DialogContent className='detailWithdraw'>
        <div className='body'>
          <div className='dec'>
            <p>تاریخ</p>
            <p>{moment().locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
          </div>
          <div className='dec'>
            <p> کیف پول</p>
            <p>کیف پول اسپات</p>
          </div>
          <div className='dec'>
            <p>رمز ارز</p>
            <div className='typeCoin'>
              <LazyLoadImage crossOrigin='anonymous' src={`${config.imgUrl}${data?.coin?.symbol?.toUpperCase()}.png`} alt="" />
              <p className='ltr'>{data?.coin?.symbol?.toUpperCase() ?? "تومان"}</p>
            </div>
          </div>
          <div className='dec'>
            <p>مقدار</p>
            <p className='ltr'>{zeroFormat(data?.amount)}&nbsp;{data?.coin?.symbol?.toUpperCase()}</p>
          </div>
          <div className='dec'>
            <p>شبکه</p>
            <p className='ltr'>{data?.network?.network?.name}</p>
          </div>
          <div className='dec'>
            <p>کارمزد شبکه</p>
            <p className='ltr'>{data?.network?.withdraw?.fee ?? 0}&nbsp;{data?.coin?.symbol?.toUpperCase()}</p>
          </div>
          <div className='dec'>
            <p>آدرس مقصد</p>
            <p className='ltr'>{data?.receiver}</p>
          </div>
          {data?.memo?.trim()?.length > 0
            && <div className='dec'>
              <p>تگ /memo</p>
              <p className='ltr'>{data?.memo ?? 0}</p>
            </div>}
          <Divider className='divder' />
          <div className='warningWithdraw'>
            <h5>وارد کردن آدرس نادرست ممکن است منجر به از دست رفتن دارایی شما شود.</h5>
            <h5>بعد از تایید , عملیات انتقال رمزارز، غیر قابل لغو می باشد. </h5>
          </div>
          <div className='btns'>
            {
              myLoading
                ? <div className="loadingCircular h41"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
                : <button className="finalCheckBtn" onClick={sendCode} > تایید  </button>
            }
            <button className="finalCheckBtn btnCancel" onClick={() => !myLoading && close()} > لغو  </button>
          </div>
        </div>
      </DialogContent>
    </>
  )
}

export default ConfirmWithdraw