import { Box, CircularProgress, Divider, Slider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { config } from '../../../../config/config';
import { useDispatch, useSelector } from 'react-redux';
import { zeroFormat, removeComma, toEnglishDigits } from '../../../../helpers/tools';
import { marksSlidBar } from '../../../../config/variable';
import { useTranslation } from 'react-i18next';
import gather from '../../../../helpers/gather';
import _ from 'lodash';
import * as DialogShow from '../../../../services/redux/dialog';
import UseVoucher from '../../dialog/UseVoucher';
import DetailOrder from '../../dialog/DetailOrder';
import DetailVoucherPerfict from '../../dialog/DetailVoucherPerfict';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useTicker from '../../../../hooks/useTicker';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
import { menu } from '../../../../config/menu';
import NeedLogin from '../../../../components/NeedLogin';
import useSnack from '../../../../hooks/useSnack';

const BodyOrderVoucher = ({ orderChange, setOrderChange, coin, setReload }: any) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const listVoucherObj: any = useSelector((state: any) => state.publicApis?.listVoucherObj);
  const mainWalletObj = useSelector((state: any) => state.user?.mainWalletObj);
  const userIsLogin = useSelector((state: any) => state.dataSaver.userIsLogin);
  const [payment, setPayment] = useState(0);
  const [amount, setAmount] = useState(0);
  const [valueSlider, setValueSlider] = useState(100);
  const ticker = useTicker();
  const snackUse = useSnack();
  const [myLoading, setMyLoading] = useState(false);

  const handelChange = (e: any) => {
    setAmount(removeComma(e));
    const price = ticker[orderChange ? "buy" : "sell"](coin?.symbol + 'tmn');
    const total = orderChange
      ? Number(removeComma(e)) / Number(price ?? 0)
      : Number(removeComma(e)) * Number(price ?? 0)

    setPayment(removeComma(zeroFormat(total, listVoucherObj[coin?.symbol]?.precision, false)));

    const balance = mainWalletObj[orderChange ? 'tmn' : coin?.symbol]?.free;
    const pers = 100 - ((Number(removeComma(e)) / balance) * 100);
    setValueSlider(pers);
  }

  const handelChangeTMN = (e: any) => {
    setPayment(removeComma(e));
    const price = ticker[orderChange ? "buy" : "sell"](coin?.symbol + 'tmn');
    const total = !orderChange
      ? Number(removeComma(e)) / Number(price ?? 0)
      : Number(removeComma(e)) * Number(price ?? 0)

    setAmount(removeComma(zeroFormat(total, listVoucherObj[coin?.symbol]?.precision, false)));

    const balance = mainWalletObj[orderChange ? 'tmn' : coin?.symbol]?.free;
    const pers = 100 - ((Number(removeComma(total)) / balance) * 100);
    setValueSlider(pers);
  }

  const handleSliderBar = (event: Event, newValue: number | number[]) => {
    setValueSlider(newValue as number);
    const balance = mainWalletObj[orderChange ? 'tmn' : coin?.symbol]?.free;

    const percent = 100 - Number(newValue);

    const amount = balance * (percent / 100) || 0;
    handelChange(zeroFormat(amount, listVoucherObj[coin?.symbol]?.precision, false))
  }


  const showSell = () => {
    if (_.isEmpty(coin)) {
      snackUse.showError('لطفا یک ارز را انتخاب کنید');
      return
    }
    if (payment <= 0) {
      snackUse.showError('مقدار را بررسی کنید');
      return
    }
    dispatch(DialogShow.show(<UseVoucher submit={sell} close={() => dispatch(DialogShow.hide())} />))
  }

  const buy = async () => {
    if (_.isEmpty(coin)) {
      snackUse.showError('لطفا یک ارز را انتخاب کنید');
      return
    }

    if (payment <= 0 || !Number(toEnglishDigits(payment?.toString()))) {
      snackUse.showError('مقدار را وارد کنید');
      return
    }

    setMyLoading(true);

    const result = await gather(`${config.api}/v1/order/voucher/buy`).post({
      "coin": coin?._id,
      "network": coin?.networks[0]?.network?._id,
      "pair": "tmn",
      "amount": Number(toEnglishDigits(payment?.toString())),
    });

    if (result.message === 200) {
      setValueSlider(100);
      setAmount(0);
      setPayment(0);
      setReload(new Date().getTime().toString());
      dispatch(DialogShow.show(<DetailVoucherPerfict data={result?.data} dataOrdre={result?.data} deposit={true} close={() => dispatch(DialogShow.hide())} />));
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }
    setMyLoading(false);
  }

  const sell = async (address: any) => {
    setMyLoading(true);

    const result = await gather(`${config.api}/v1/order/voucher/sell`).post({
      "coin": coin?._id,
      "network": coin?.networks[0]?.network?._id,
      "pair": "tmn",
      "code": address
    })

    if (result.message === 200) {
      setValueSlider(100);
      setAmount(0);
      setPayment(0);
      setReload(new Date().getTime().toString());
      dispatch(DialogShow.show(<DetailOrder data={result?.data} close={() => dispatch(DialogShow.hide())} />));

    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }
    setMyLoading(false);
  }

  useEffect(() => {
    setAmount(0);
    setPayment(0);
  }, [coin])


  return (
    <>
      <div className='stepOrder'>
        <div>
          <span className='icon-SecurityCard'></span>
          <h4>مرحله دوم <b>نوع معامله</b></h4>
          <div className='dividerVer'></div>
        </div>
        <p>نوع معامله را مشخص کنید</p>
      </div>
      <div className='bodyFastOrder bodyOrderCurrency'>
        <div className='right'>
          <div className='btnChangeOrder'>
            <button className={`buy ${orderChange && 'active'}`} onClick={() => { setOrderChange(true); setPayment(0); setAmount(0) }}>
              <span className='m-IconBuy'></span>
              <p>خرید</p>
            </button>
            <button className={`sell ${!orderChange && 'active'}`} onClick={() => { setOrderChange(false); setPayment(0); setAmount(0) }}>
              <span className='m-IconSell'></span>
              <p>فروش</p>
            </button>
          </div>
          <div className='enterAmount'>
            <div className='imgInput'>
              <LazyLoadImage crossOrigin='anonymous' src={!orderChange ? `${config.imgUrl}${coin?.symbol?.toUpperCase()}.png` : '/icons/iranflag.svg'} alt='' />
              <NumericFormat allowNegative={false} thousandSeparator={true} value={amount > 0 ? amount : ''} onChange={(e) => { handelChange(e.target.value) }} placeholder='پرداخت میکنم' />
            </div>
            <div className='btnExchange' onClick={() => { setOrderChange(!orderChange); setPayment(amount); setAmount(payment) }}>
              <LazyLoadImage crossOrigin='anonymous' src='/icons/m-IconRepeat.svg' alt='' />
            </div>
            <div className='imgInput'>
              <LazyLoadImage crossOrigin='anonymous' src={orderChange ? `${config.imgUrl}${coin?.symbol?.toUpperCase()}.png` : '/icons/iranflag.svg'} alt='' />
              <NumericFormat allowNegative={false} thousandSeparator={true} value={payment > 0 ? payment : ''} onChange={(e) => handelChangeTMN(e.target.value)} placeholder='دریافت میکنم' />
            </div>
          </div>
          <p className='wrningText opacity0'>مقدار وارد شده، از حداکثر مقدار مجاز بیشتر می‌باشد.</p>
          <div className='priceNow'>
            <h4>قیمت تومانی در لحظه:  <b>TMN {zeroFormat(ticker[orderChange ? "buy" : "sell"](coin?.symbol + 'tmn'))}</b></h4>
          </div>
          {orderChange
            && <div className='sliderRage my-20'>
              <Box sx={{ width: '100%' }}>
                <Slider
                  aria-label='Restricted values'
                  track='inverted'
                  value={typeof valueSlider === 'number' ? valueSlider < 0 ? 100 : valueSlider : 100}
                  onChange={handleSliderBar}
                  marks={marksSlidBar}
                />
              </Box>
            </div>}

          {userIsLogin
            ?
            myLoading
              ? <div className={`loadingCircularOrder ${orderChange ? 'buy' : 'sell'}`}><CircularProgress size={28} style={{ color: "#fff" }} /></div>
              : <div className={`btnConfirm ${orderChange ? 'buy' : 'sell'}`} onClick={() => (orderChange ? buy() : showSell())}>
                <p> (با تایید {orderChange ? 'خرید' : 'فروش'} قوانین و مقررات را می پذیرم)</p>
                <Divider variant='middle' orientation='vertical' flexItem />
                <button>تایید {orderChange ? 'خرید' : 'فروش'}</button>
              </div>
            : <div className={`btnConfirm justifyCenter ${orderChange ? 'buy' : 'sell'}`}
              onClick={() => dispatch(DialogShow.show(<NeedLogin submit={() => { dispatch(DialogShow.hide()) }} />))}>
              <p className='textCenter'>ثبت نام - ورود</p>
            </div>}
        </div>
        {(orderChange && userIsLogin)
          && <div className='left'>
            <NavLink to={menu.financial.childs.depositAll.childs.depositCash.childs.default.url} className='cPointer flexCenter ml-5'>
              <IoIosAddCircleOutline color='var(--color-textDone)' />
            </NavLink>
            <h4>موجودی شما : </h4>
            <h4 className='cPointer mr-3' onClick={() => handelChange(mainWalletObj['tmn']?.free)}>{zeroFormat(mainWalletObj['tmn']?.free, 1, false)}</h4>
            <h4 className='mr-2'>TMN</h4>
          </div>}
      </div>
    </>
  )
}

export default BodyOrderVoucher