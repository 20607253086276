import React, { memo, useEffect, useState } from 'react';
import CustomInput from '../../../../components/CustomInput';
import { CircularProgress, Slider } from '@mui/material';
import { zeroFormat, removeComma, toEnglishDigits, autoFormatter } from '../../../../helpers/tools';
import { useDispatch, useSelector } from 'react-redux';
import * as BackdropShow from '../../../../services/redux/backdrop';
import gather from '../../../../helpers/gather';
import { config } from '../../../../config/config';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import useTicker from '../../../../hooks/useTicker';
import { NavLink } from 'react-router-dom';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { menu } from '../../../../config/menu';
import * as DialogShow from '../../../../services/redux/dialog';
import NeedLogin from '../../../../components/NeedLogin';
import useSnack from '../../../../hooks/useSnack';
import ConfirmBuySell from '../../dialog/ConfirmBuySell';
import { useLocalStorage } from 'usehooks-ts';

const BuySell = ({ ordersBook, flag, coin, base, typePrice, setReload, price, setPrice, amount, setAmount, total, setTotal }:
  { ordersBook: any, flag: boolean, coin: any, base: any, typePrice: any, setReload: any, price: any, setPrice: any, amount: any, setAmount: any, total: any, setTotal: any }) => {
  const mainWalletObj = useSelector((state: any) => state.user?.mainWalletObj);
  const profile = useSelector((state: any) => state.user?.profile);
  const userIsLogin = useSelector((state: any) => state.dataSaver.userIsLogin);
  const ticker = useTicker();
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [amountError, setAmountError] = useState(false);
  const [stopPrice, setStopPrice] = useState(0);
  const [stopError, setStopError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [valueSlider, setValueSlider] = React.useState(0);
  const [limitBuy, setLimitBuy] = useState(false);
  const [limitSell, setLimitSell] = useState(false);
  const snackUse = useSnack();
  const [confiemOrder] = useLocalStorage('confiemOrderPro', "false");
  const [myLoading, setMyLoading] = useState(false);
  const [bestNum, setBestNum] = useState(ordersBook)
  const marks = [
    { value: 0, },
    { value: 25, },
    { value: 50, },
    { value: 75, },
    { value: 100 },
  ];

  const resetState = () => {
    setPrice(typePrice == "market" ? ticker.price(coin?.pairSymbol) : 0);
    setAmount(0);
    setValueSlider(0)
    setStopPrice(0)
    setTotal(0);
    setLimitBuy(false);
  }

  const handleSliderBar = (event: Event, newValue: number | number[]) => {
    setValueSlider(newValue as number);
    const balance = mainWalletObj[flag ? coin?.myPair : coin?.symbol]?.free;

    const percent = Number(newValue);

    const amount = balance * (percent / 100) || 0;


    const newPrice = price > 0 ? price : ticker.price(coin?.pairSymbol)
    if (price <= 0) {
      setPrice(ticker.price(coin?.pairSymbol))
    }

    const final = flag ? amount / newPrice : amount
    const myPercent = Number((0.5 * final) / 100);
    if (flag) {
      setAmount(removeComma(zeroFormat((final - myPercent), coin?.pair[base]?.precision, false)));
    } else {
      setAmount(removeComma(final));
    }
    setTotal(removeComma(autoFormatter(final * newPrice)))
  }

  const changeAmount = (e: any) => {
    setAmount(e);
    setTotal(removeComma(autoFormatter(e * price)))

    const total = flag
      ? Number(removeComma(e)) * Number(price ?? 0)
      : Number(removeComma(e))

    const balance = mainWalletObj[flag ? coin?.myPair : coin?.symbol]?.free;
    const pers = ((Number(removeComma(total)) / balance) * 100);
    setValueSlider(pers > 100 ? 100 : pers);

  }

  const handleTotal = () => {
    const fee = Number(profile?.plane?.level?.fee?.[flag ? "buy" : "sell"]);
    if (flag) {
      return zeroFormat((fee > 0 ? Number(amount - ((fee / 100) * amount)) : Number(amount)),coin?.pair[base]?.precision, false)
    } else {
      return autoFormatter(fee > 0 ? Number((amount * price) - ((fee / 100) * amount)) : Number(amount * price))
    }
  }

  const handleClickBalance = (balanceUser: any) => {
    let a = 0;
    if (flag) {
      let newAmount = Number(balanceUser / ticker.price(coin?.pairSymbol));
      if (newAmount) {
        const myPercent = Number((0.5 * newAmount) / 100);
        setAmount(Number(removeComma(zeroFormat(newAmount - myPercent, coin?.pair[base]?.precision, false))));
        a = newAmount;
      } else {
        setAmount(0);
        a = 0
      }
    } else {
      setAmount(balanceUser);
      a = balanceUser
    }

    setPrice(ticker.price(coin?.pairSymbol));
    setTimeout(() => {
      setTotal(removeComma(a * ticker.price(coin?.pairSymbol)))
    }, 150);
  }

  const handleTotalChange = (e: any) => {
    setTotal(e)
    if (Number(e) > 0) {
      let newAmount = Number(e) / Number(ticker.price(coin?.pairSymbol))
      setPrice(ticker.price(coin?.pairSymbol));
      setAmount(removeComma(autoFormatter(newAmount)))
    }
  }

  const handleOffer = () => {
    if (typePrice != "market") {
      const p = bestNum || 0
      setPrice(p)
      setTotal(amount * p)
    }
  }

  const confirmSend = () => {
    const max = (ticker.price(coin?.pairSymbol) + ((5 * ticker.price(coin?.pairSymbol)) / 100))
    const min = (ticker.price(coin?.pairSymbol) - ((5 * ticker.price(coin?.pairSymbol)) / 100));

    if ((typePrice == "stop" && stopPrice <= 0)) {
      snackUse.showError("قیمت توقف معتبر نیست");
      setStopError(true)
      setTimeout(() => { setStopError(false) }, 300);
      return
    }

    // if (Number(coin?.pair[base?.toLocaleLowerCase()]?.min) > Number(toEnglishDigits(amount?.toString()))) {
    if (Number(coin?.pair[base?.toLocaleLowerCase()]?.min) > Number(toEnglishDigits(total?.toString()))) {
      if (flag) {
        setLimitBuy(true)
      } else {
        setLimitSell(true);
      }
      snackUse.showError("حداقل میزان سفارش را بررسی کنید");
      return
    }

    if (_.isEmpty(coin)) {
      snackUse.showError("لطفا یک ارز را انتخاب کنید");
      return
    }

    if (price <= 0) {
      snackUse.showError("قیمت را وارد کنید");
      setPriceError(true)
      setTimeout(() => { setPriceError(false) }, 300);
      return
    }

    if (!(price > min && price < max)) {
      snackUse.showError("قیمت معتبر نیست");
      setPriceError(true)
      setTimeout(() => { setPriceError(false) }, 300);
      return
    }

    if (amount <= 0) {
      snackUse.showError("مقدار را وارد کنید");
      setAmountError(true);
      setTimeout(() => { setAmountError(false) }, 300);
      return
    }

    setLimitSell(false);
    setLimitBuy(false);

    if (confiemOrder == "true") {
      send()
    } else {
      const data = {
        "coin": coin,
        "side": flag ? "buy" : "sell",
        "price": Number(toEnglishDigits(price?.toString())),
        "amount": Number(toEnglishDigits(amount?.toString())),
        "trade": typePrice,
        "total": Number(toEnglishDigits(total?.toString())),
        "fee": zeroFormat(Number(profile?.plane?.level?.fee?.[flag ? "buy" : "sell"])),
        "stop": typePrice == "stop" ? Number(toEnglishDigits(stopPrice?.toString())) : null
      }
      dispatch(DialogShow.show(<ConfirmBuySell data={data} send={send} close={() => dispatch(DialogShow.hide())} />))
    }
  }

  const send = async () => {
    setMyLoading(true);

    const body = {
      "coin": coin._id,
      "pair": base,
      "side": flag ? "buy" : "sell",
      "price": Number(toEnglishDigits(price?.toString())),
      "amount": Number(toEnglishDigits(amount?.toString())),
      "trade": typePrice,
      "stop": typePrice == "stop" ? Number(toEnglishDigits(stopPrice?.toString())) : null
    }

    const result = await gather(`${config.api}/v1/order`).post(body);

    if (result.message === 200) {
      dispatch(DialogShow.hide());
      setReload(new Date().getTime().toString());
      snackUse.showSaccess("سفارش شما با موفقیت ثبت شد");
      resetState();
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    dispatch(BackdropShow.hide());
    setMyLoading(false);
  }

  useEffect(() => {
    resetState();
  }, [coin, typePrice])

  useEffect(() => {
    setBestNum(ordersBook)
  }, [ordersBook])

  return (
    <div className='boxBS'>
      <div className='titleBS'>
        <h3>{flag ? "خرید" : "فروش"} {coin?.symbol.toUpperCase()}</h3>
        {userIsLogin
          && <div className='cPointer'>
            <NavLink
              to={(flag ? base : coin?.symbol) == "tmn" ? menu.financial.childs.depositAll.childs.depositCash.childs.default.url : menu.financial.childs.depositAll.childs.depositCoin.url + "/" + (flag ? base?.toUpperCase() : coin?.symbol?.toUpperCase())}
              className='cPointer flexCenter'>
              <IoIosAddCircleOutline />
            </NavLink>
            {/* <p>{zeroFormat(mainWalletObj[flag ? coin?.myPair : coin?.symbol]?.free, coin?.pair[base]?.precision, false)} {flag ? base?.toUpperCase() : coin?.symbol.toUpperCase()}</p> */}
            <p onClick={() => handleClickBalance(mainWalletObj[flag ? coin?.myPair : coin?.symbol]?.free)}>
              {autoFormatter(mainWalletObj[flag ? coin?.myPair : coin?.symbol]?.free)} {flag ? base?.toUpperCase() : coin?.symbol.toUpperCase()}
            </p>
            <span className='icon-Wallt15'></span>
          </div>
        }
      </div>
      {typePrice == "stop"
        && <CustomInput error={stopError} inputState={stopPrice} setInputSatet={(e: any) => setStopPrice(e)} label={""}
          classEnd={'styleP'} classStart={"brNone styleP"} startEle={<p className='colorGray '>{base?.toUpperCase()}</p>}
          endEle={<p className='colorGray '>قیمت توقف</p>} className={"ltr mb-15 "} placeholder={""} />}
      {
        typePrice == "market"
          ? <CustomInput readOnly={true} error={false} inputState={ticker.price(coin?.pairSymbol)} setInputSatet={() => { }} label={""}
            classEnd={'styleP'} classStart={"brNone styleP"} startEle={<p className='colorGray '>{base?.toUpperCase()}</p>}
            endEle={<p className='colorGray '>قیمت</p>} className={"ltr mb-15 "} placeholder={""} />
          : <CustomInput error={priceError} inputState={price} setInputSatet={(e: any) => { setPrice(e); setTotal(amount * e) }} label={""}
            classEnd={'styleP'} classStart={"brNone styleP"} startEle={<p className='colorGray '>{base?.toUpperCase()}</p>}
            endEle={<p className='colorGray '>قیمت</p>} className={"ltr mb-15 "} placeholder={""} />
      }
      <CustomInput error={amountError} inputState={amount} setInputSatet={changeAmount} label={""} classEnd={'styleP'} classStart={"brNone styleP"} startEle={<p className='colorGray '>{coin?.symbol.toUpperCase()}</p>} endEle={<p className='colorGray '>مقدار</p>} className={"ltr mb-12"} placeholder={""} />

      <div className='bestAmount'>
        <p>بهترین پیشنهاد {flag ? 'خرید' : 'فروش'}: </p>
        <p className='cPointer' onClick={handleOffer}><b>{coin?.myPair?.toUpperCase()}</b>{zeroFormat(bestNum)}</p>
      </div>
      <div className='sliderBS'>
        <Slider
          onChange={handleSliderBar}
          aria-label="SilderBar"
          marks={marks}
          step={25}
          value={typeof valueSlider === 'number' ? valueSlider > 100 ? 0 : valueSlider : 0}
          sx={{
            color: 'var(--color-sliderBr)',
            '& .MuiSlider-track': {
              border: `3px solid ${flag ? "#22c58b" : "#ff6666"}`,
            },
            '& .MuiSlider-thumb': {
              width: 19,
              height: 19,
              backgroundColor: '#fff',
              border: `4px solid ${flag ? "#22c58b" : "#ff6666"}`,
              '&::before': {
                boxShadow: '0px 7.45px 14.91px -7.45px #0f0f0f33',
              },
              '&:hover, &.Mui-focusVisible, &.Mui-active': {
                boxShadow: 'none',
              },
            },
            '& .MuiSlider-mark': {
              backgroundColor: "#e0e0e0",
              width: 8,
              height: 8,
              borderRadius: 50,
            },
            '& .MuiSlider-markActive': {
              backgroundColor: `${flag ? "#22c58b" : "#ff6666"}`,
            }
          }}
        />
      </div>
      <CustomInput id={"totalAmount"} inputState={total} setInputSatet={handleTotalChange} label={""}
        classEnd={'brNone styleP'} classStart={"brNone styleP"}
        startEle={<p className='colorGray '>{base?.toUpperCase()}</p>}
        endEle={<p className='colorGray '>مبلغ کل</p>} className={"ltr mb-15 "} placeholder={""} />
      {flag
        ? <div className={`priceLimit ${limitBuy ? "" : "opacity0"}`}>
          <p>حداقل خرید </p>
          <h5>{zeroFormat(Number(coin?.pair[base?.toLocaleLowerCase()]?.min))}&nbsp; {base?.toUpperCase()}</h5>
        </div>
        : <div className={`priceLimit ${limitSell ? "" : "opacity0"}`}>
          <p>حداقل فروش </p>
          <h5>{zeroFormat(Number(coin?.pair[base?.toLocaleLowerCase()]?.min))}&nbsp; {base?.toUpperCase()}</h5>
        </div>
      }
      {userIsLogin
        ? myLoading
          ? <div className={`loadingCircular ${flag ? "" : "sell"}`}><CircularProgress size={28} style={{ color: "#fff" }} /></div>
          : <button onClick={confirmSend}
            className={`submitBS ${flag ? "" : "sell"}`}>{flag ? "خرید" : "فروش"} {coin?.name} ({coin?.symbol.toUpperCase()})
          </button>
        : <button onClick={() => dispatch(DialogShow.show(<NeedLogin submit={() => { dispatch(DialogShow.hide()) }} />))}
          className={`submitBS ${flag ? "" : "sell"}`}>ثبت نام - ورود
        </button>
      }
      <div className='priceFee'>
        <p>کارمزد</p>
        <h5>{zeroFormat(Number(profile?.plane?.level?.fee?.[flag ? "buy" : "sell"]))}&nbsp; درصد</h5>
      </div>
      <div className='priceFee'>
        <p>دریافتی شما</p>
        <h5>{handleTotal()} &nbsp; {flag ? coin?.symbol?.toUpperCase() : base?.toUpperCase()}</h5>
      </div>
    </div>
  )
}

export default memo(BuySell) 